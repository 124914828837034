import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'

import './footer.less'

const Footer = () => (
  <div className='footer__container' >
    <div className='container__max'>

      <footer className='footer'>
        <div>
            &copy; { moment().format('YYYY') } Tueri. All rights reserved.
        </div>
        <div>
            <h2>Features</h2>
            {/* <Link to='/features/'>Image processing</Link>
            <Link to='/features/'>Image resizing</Link> */}
            <Link to='/features/image-optimization'>Image optimization</Link>
            <Link to='/features/responsive-images'>Responsive images</Link>
            <Link to='/features/lazy-loading-images'>Lazy-loading images</Link>
            <Link to='/features/progressive-loading-images'>Progressive loading images</Link>
            <Link to='/features/remote-image-sources'>Remote image sources</Link>
            {/* <Link to='/features/'>Feature Roadmap</Link> */}
        </div>
        <div>
            <h2>Company</h2>
            <Link to='/terms/'>Terms of service</Link>
            <Link to='/privacy/'>Privacy policy</Link>
        </div>
        <div>
            <h2>Contact</h2>
            <a href="mailto:support@tueri.io">Support</a>
            <a href="mailto:sales@tueri.io">Sales</a>
            <a target="_blank" rel="noopener noreferrer" href="https://status.tueri.io/">System status</a>
        </div>
      </footer>
    </div>
  </div>
)

export default Footer
