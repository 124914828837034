import React from 'react'
import PropTypes from 'prop-types'
import './hero.css'

function Hero({ title, subTitle, date, large }) {
    return(
        <div className={`hero__container ${ large && 'hero__container--large' }`}>
            <h1>{ title }</h1>
            {
                subTitle ? (
                    <h2>{ subTitle }</h2>
                ) : null
            }

            {
                date ? (
                    <div className='hero__containerDate'>{ date }</div>
                ) : null
            }
        </div>
    )
}

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string
}

export default Hero