import React from 'react'
import PropTypes from 'prop-types'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import './button.less'

function Button({ children, large, fullWidth, link, style }) {

    const buttonLarge = large ? 'button--large' : ''
    const buttonFullWidth = fullWidth ? 'button--fullWidth' : ''

    if (link) {
        return <OutboundLink href='https://app.tueri.io/signup' className={`button ${buttonLarge} ${buttonFullWidth}`}>{ children }</OutboundLink>
    }

    return (
        <button style={style} className={`button ${buttonLarge} ${buttonFullWidth}`}>{ children }</button>
    )
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Button