import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import TueriProvider from '@tueri/react'

import Header from './Header'
import Footer from './Footer'
import './layout.less'
import './code.less'

const Layout = ({ children, blog, home }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Effortless image management for your website, app, email campaign or e-commerce store.' },
            { name: 'keywords', content: 'image processing, image optimization, image hosting, responsive images, lazy-loading, placeholder images, image resizing, photo storage, image management, custom email images, personalized email image, image transformation, real time image processing' },
            { property: 'og:title', content: 'Tueri - Effortless Image Management' },
            { property: 'twitter:title', content: 'Tueri - Effortless Image Management' },
            { property: 'og:type', content: 'website' },
            { property: 'og:image', content: 'https://cdn.tueri.io/274877906996/tueri-effortless-image-management.jpg' },
            { property: 'twitter:image:src', content: 'https://cdn.tueri.io/274877906996/tueri-effortless-image-management.jpg' },
            { property: 'og:url', content: 'https://tueri.io' },
            { property: 'og:description', content: 'Real-time image optimization platform for your website, app, email campaign or e-commerce store.' },
            { property: 'twitter:description', content: 'Real-time image optimization platform for your website, app, email campaign or e-commerce store.' },
            { property: 'twitter:card', content: 'summary_large_image' },
            { property: 'author', content: 'Dane Stevens' },
            { name: 'p:domain_verify', content: 'd1b23d31aa0814d912ff1ba0ad92cb8f' }
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <TueriProvider>
          <div className='content__container'>
            <div
              className={`container__max ${blog ? 'container__max--blog' : ''} ${home ? 'container__max--home' : ''}`}
            >
              {children}
            </div>
          </div>
        </TueriProvider>
        <Footer/>

      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
