import React from 'react'
import './term.less'

const Term = ({ title, children }) => {
    return(
        <div className='term'>
            <h2>{ title }</h2>
            { children }
        </div>
    )
}

export default Term