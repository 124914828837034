import React from 'react'
import Nav from '../Nav'
import { Link } from 'gatsby'

import './header.less'

const Header = () => (
  <div className='header__container' >
    <div className='container__max'>

      <header className='header'>
        <Link className='header__logo' to='/'>
          Tueri
        </Link>

        <Nav />
      </header>
    </div>
  </div>
)

export default Header
