import React from 'react'
import { Link } from 'gatsby'
import Button from '../Button'

import './nav.css'

class Nav extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            expanded: false
        }

        this.handleNavToggle = this.handleNavToggle.bind(this)
    }

    handleNavToggle() {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    render() {

        const { expanded } = this.state

        return(
            <React.Fragment>

                <button className='nav__toggle' onClick={ this.handleNavToggle }>
                    <i className='material-icons'>{ expanded ? 'close' : 'menu' }</i>
                </button>
                <nav className={`nav__container ${ expanded ? '' : 'nav__container--hidden' }`}>

                    <div>
                        <ul>
                            <li><Link to='/features/'>Features</Link></li>
                            <li><Link to='/pricing/'>Pricing</Link></li>
                            <li><Link to='/blog/'>Blog</Link></li>
                            <li><Link to='/docs'>Docs</Link></li>
                        </ul>
                    </div>

                    <div>
                        <ul className='nav__right'>
                            <li><a href='https://app.tueri.io'>Log in</a></li>
                            <li><Button link>Sign up for free</Button></li>
                        </ul>                   
                    </div>
                </nav>

            </React.Fragment>
        )

    }

}

export default Nav